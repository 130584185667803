import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Section, Container } from '@components/global';
import { GlobalLink } from '@components/Link';

const About = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_connection_b38q" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_gravitas_d3ep" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "undraw_inspiration_lecl" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="about">
        <Container>
          <Grid>
            <div>
              <h2>Vertrag über Abonnement der rocketbatch Software-as-a-Service (SaaS)</h2>
              <GlobalLink to="/de/wsa">
                German&nbsp;/&nbsp;
              </GlobalLink>

              <GlobalLink to="/en/wsa">
                  English&nbsp;
              </GlobalLink>
              
            </div>
            
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>


<div class="container-content"><div class="body">
<p><b>Vertrag über Abonnement der rocketbatch Software-as-a-Service (SaaS)</b></p>
<p>Dieser Vertrag über ein Abonnement für Software-as-a-Service (der "<b>Vertrag</b>") wird unter dem Datum geschlossen, an welchem Sie ("<b>Kunde</b>") gemeinsam mit dem Abonnement-Anbieter&nbsp;("<b>rocketbatch</b>") ein Auftragsformular unter Bezugnahme auf diesen Vertrag unterzeichnen ("<b>Auftragsformular</b>"). Dieses Datum gilt sodann als das "<b>Datum des Inkrafttretens</b>".</p>
<br /><p><b>Hintergrund</b><br /></p>
<p>(A) rocketbatch hat ein sowohl über ihre Mobile App als auch das Web zugängliches mobiles Kommunikationssystem entwickelt, das sie Abonnenten über das Internet auf Abonnementbasis zur Verfügung stellt.</p>
<p>(B) Der Kunde möchte den Service von rocketbatch für seine Geschäftstätigkeit nutzen.</p>
<p>(C) Nach Maßgabe der Bedingungen dieses Vertrages hat sich rocketbatch zur Bereitstellung des rocketbatch-Service verpflichtet, und der Kunde dazu, diesen Service zu abonnieren und zu bezahlen.</p>
<br /><p><b>Vereinbarte Konditionen</b><br /></p><br />
<p><b>1. Definitionen.</b></p><br />
<p>Die im Vertrag nicht anderweitig definierten Begriffe haben die folgende Bedeutung:</p>
<p><b>Verbundene Unternehmen: </b>juristische Person, die eine der Parteien dieses Vertrages direkt oder indirekt kontrolliert, von dieser kontrolliert wird oder unter gemeinsamer Kontrolle mit dieser steht. Im Sinne des Vorstehenden bezeichnet "Kontrolle" das Eigentum an (i) mehr als 50 % (fünfzig Prozent) der Stimmrechte zur Wahl von Direktoren (Verwaltungsratsmitgliedern) der juristischen Person, oder (ii) mehr als 50 % (fünfzig Prozent) der Eigentumsrechte an der juristischen Person.</p>
<p><b>Autorisierte Nutzer</b>: alle Angestellten, Vertreter und unabhängige Auftragnehmer des Kunden, denen der Kunde die Nutzung der Services gestattet hat, wie näher in Ziff. 2.3 beschrieben;</p>
<p><b>Daten autorisierter Nutzer</b>: personenbezogene Daten und Informationen, die rocketbatch von den autorisierten Nutzern mitgeteilt werden und/oder vom Kunden in den Service zur Einrichtung eines Kontos für autorisierte Nutzer eingegeben wurden, nicht jedoch Kunden-Daten und rocketbatch-Daten;</p>
<p><b>rocketbatch-Daten:</b> bezeichnet (i) die dem Kunden im Rahmen der Services von rocketbatch mitgeteilten Informationen und Daten; (ii) die von rocketbatch aus der Nutzung der Services durch den Kunden entnommenen Metadaten, die zur Erbringung der Services genutzt werden; und (iii) Feedback oder Vorschläge des Kunden oder der autorisierten Nutzer an rocketbatch in Bezug auf die Services ;</p>
<p><b>Kundendaten:</b> Daten und Informationen, die der Kunde rocketbatch zur Verfügung gestellt hat und/oder die vom Kunden, autorisierten Nutzern, oder rocketbatch im Namen des Kunden zwecks Nutzung der Services oder zur Ermöglichung der Nutzung der Services durch den Kunden eingegebenen Daten, oder die vom oder für den Kunden durch dessen Nutzung der Services erhobenen und bearbeiteten Daten, nicht jedoch rocketbatch-Daten und Daten autorisierter Nutzer;</p>
<p><b>Anspruchsjahr:</b> bezeichnet jeweils einen aufeinander folgenden Zeitraum von 12 (zwölf) Monaten, der ab dem Datum des Inkrafttretens dieses Vertrages läuft;</p>
<p><b>Vertrauliche Informationen:</b> eigentumsrechtlich geschützte oder vertrauliche Informationen einer Partei, die entweder eindeutig als solche bezeichnet werden oder als vertrauliche Informationen in Ziffer 10 gekennzeichnet sind, und/oder die zum Zeitpunkt ihrer Offenlegung nach vernünftigem Ermessen als vertraulich oder eigentumsrechtlich geschützt anzusehen sind;</p>
<p><b>Arbeitstag: </b>wie in der rocketbatch Service Level Agreement (SLA) beschrieben;</p>
<p><b>Geschäftszeiten:</b> wie in der rocketbatch Service Level Agreement (SLA) beschrieben;</p>
<p><b>Notfallwiederherstellungsrichtlinie: </b>die aktuelle rocketbatch-Notfallwiederherstellungsrichtlinie in ihrer jeweils von rocketbatch geänderten Fassung;</p>
<p><b>Gebühr:</b> bezeichnet die Abonnementsgebühr sowie alle zusätzlichen Kosten oder Auslagen, die sich aus dem Auftragsformular ergeben;</p>
<p><b>Erstlaufzeit des Abonnements:</b> die Erstlaufzeit des Abonnements ist im Auftragsformular angegeben;</p>
<p><b>Unangemessene Inhalte: </b>Inhalte, welche (a) ungesetzlich, schädlich, bedrohend, verleumderisch oder obszön sind, gegen Urheberrecht verstoßen, eine Belästigung darstellen, rassistisch bzw. ethnisch beleidigend sind; (b) rechtswidrigen Handlungen Vorschub leisten; (c) sexuell eindeutige Bilder zeigen; (d) gesetzeswidrige Gewalt fördern; (e) aufgrund Rasse, Geschlecht, Hautfarbe, Glaubensangehörigkeit, sexueller Orientierung, Behinderung diskriminierend sind; oder (f) Personen- oder Sachschaden verursachen;</p>
<p><b>Rechte am geistigen Eigentum:</b> insbesondere Patentrechte, Markenrechte, Rechte an Dienstleistungsmarken, Handelsnamen, sonstige, die Handelstätigkeit kennzeichnende Symbole, sowie Erfindungen, Urheberrechte, Geschmacksmusterrechte, Rechte an Datenbanken Rechte am Know-how, Geschäftsgeheimnisse sowie alle anderen Rechte am geistigen Eigentum, die weltweit entstehen, gleich ob sie eingetragen oder nicht eingetragen sind, und einschließlich der Anmeldungen zwecks Gewährung dieser Rechte;</p>
<p><b>Auftragsformular:</b> das vom Kunden rocketbatch vorgelegte Auftragsformular mit allen Einzelheiten der Services, welches Bestandteil dieses Vertrages ist;</p>
<p><b>Datenschutzerklärung:</b> die Datenschutzerklärung von rocketbatch in ihrer jeweils gültigen Fassung (https://rocketbatch.com/privacy-policy/).</p>
<p><b>Verlängerungszeitraum:</b> hat die in nachstehender Ziff. 14.1 dargelegte Bedeutung;</p>
<p><b>Service(s):</b> bezeichnet (i) die Nutzung der Software nach Maßgabe dieses Vertrages; (ii) die Nutzung der rocketbatch-Daten; (iii) Erbringen von Supportleistungen, und (iv) Zugriff auf die rocketbatch-Hosting Plattform;</p>
<p><b>Software:</b> die von rocketbatch im Rahmen der Services bereitgestellten Softwareanwendungen für interne Kommunikation wie im Auftragsformular näher angegeben;</p>
<p><b>Abonnementgebühr:</b> die vom Kunden an rocketbatch für die Nutzerabonnements zu zahlende, im Auftragsformular näher angegebene Abonnementgebühr;</p>
<p><b>Laufzeit des Abonnements:</b> bezeichnet die Erstlaufzeit des Abonnements und alle sich daran anschließenden Verlängerungszeiträume;</p>
<p><b>Supportleistungen:</b> bezeichnet die rocketbatch-Standard-Supportleistungen, die von rocketbatch gemäß der Richtlinie über Supportleistungen in Bezug auf die Software erbracht werden, einschließlich aller verfügbaren Upgrades der Softwarewartung;</p>
<p><b>Richtlinie SLA Supportleistungen:</b> rocketbatch-Richtlinie für die Erbringung von Supportleistungen für den Kunden und, soweit zutreffend, vom Kunden dazugekaufte erweiterte Supportleistungen, die dem Kunden mitunter zur Verfügung gestellt werden;</p>
<p><b>Steuern: </b>insbesondere Quellen-, Verkaufs-, Nutzungs-, Verbrauchs-, Mehrwertsteuer und ähnliche Steuern, nicht jedoch Steuern auf der Grundlage des Bruttoumsatzes von rocketbatch;</p>
<p><b>Fremdanwendungen: </b>bezeichnet Online-Anwendungen und Offline-Softwareprodukte, die von Dritten angeboten und gemeinsam mit den Services verwendet werden wie im rocketbatch Deklarationsformular für die Verwendung von Drittanbietern aufgeführt (3rd Party Use Statement Declaration Form), das unter den Datenschutzerklärung von rocketbatch verfügbar ist;</p>
<p><b>Nutzerabonnements: </b>die vom Kunden gemäß diesem Vertrag erworbenen Nutzerabonnements für die im Auftragsformular angegebene Anzahl autorisierter Nutzer, die diesen autorisierten Nutzern das Recht auf den Zugriff auf die Services und deren Nutzung nach Maßgabe dieses Vertrages einräumen; und</p>
<p><b>Virus:</b> alle Dinge und Vorrichtungen (einschließlich Software, Codes, Dateien oder Programme), die den Zugriff auf Computersoftware, -hardware oder -netzwerke, Telekommunikationsdienste, Ausrüstung oder Netzwerke oder sonstige Leistungen oder Einrichtungen bzw. deren Betrieb, Zuverlässigkeit oder Nutzererlebnis verhindern, stören oder in sonstiger Weise beeinträchtigen, einschließlich Würmer, Trojaner, Viren und ähnliche Dinge oder Vorrichtungen.</p>
<br />
<p><b>2. Nutzerabonnement</b></p>
<br />
<p>2.1 rocketbatch erbringt die Services während der Laufzeit des Abonnements gemäß den Bedingungen dieses Vertrages.</p>
<p>2.2 Unter der Voraussetzung, dass der Kunde die Nutzerabonnement(s) erwirbt und vorbehaltlich der in dieser Ziffer 2 genannten Einschränkungen und der Vertragsbedingungen gewährt rocketbatch hiermit dem Kunden das nicht exklusive, nicht übertragbare Recht, den autorisierten Nutzern die Nutzung der Services während der Laufzeit des Abonnements ausschließlich für die interne Kommunikation des Kunden zu gestatten.</p>
<p>2.3 Der Kunde verpflichtet sich in Bezug auf die autorisierten Nutzer, dass:</p>
<p>2.3.1 die Höchstanzahl der autorisierten Nutzer, welchen er den Zugriff auf die Services und deren Nutzung gestattet, die Anzahl der vom Kunden jeweils erworbenen Nutzerabonnements nicht übersteigt. Der Kunde verpflichtet sich weiterhin, nicht zuzulassen, dass ein Nutzerabonnement von mehr als einem pro individuell autorisierten Nutzer genutzt wird, es sei denn das Abonnement wurde vollständig auf einen anderen individuell autorisierten Nutzer übertragen, in welchem Fall der ursprüngliche autorisierte Nutzer nicht mehr zum Zugriff auf die Services und deren Nutzung befugt ist;&nbsp;</p>
<p>2.3.2 jeder autorisierte Nutzer für die Nutzung der Services durch ihn ein sicheres Passwort zu erstellen hat, und dass dieses Passwort häufig zu ändern und von jedem autorisierten Nutzer vertraulich zu behandeln ist.</p>
<p>2.4 Bei der Nutzung der Services greift der Kunde nicht auf Viren oder sonstiges Material, insbesondere Kundendaten und Daten autorisierter Nutzer zu, die:</p>
<p>2.4.1 ungesetzlich, schädlich, bedrohend, verleumderisch oder obszön sind, gegen Urheberrecht verstoßen, eine Belästigung darstellen, rassistisch bzw. ethnisch beleidigend sind;</p>
<p>2.4.2 gesetzwidrigen Handlungen Vorschub leisten; oder</p>
<p>2.4.3 auf sonstige Weise unrechtmäßig sind oder Personen- oder Sachschaden verursachen bzw. speichert, verbreitet oder überträgt selbige, und rocketbatch behält sich unverbindlich und unbeschadet ihrer sonstigen Rechte gegenüber dem Kunden das Recht vor, den Zugriff des Kunden auf sämtliches Material zu sperren, das die Bestimmungen dieser Vertragsklausel verletzt.</p>
<p>2.5 Der Kunde wird:</p>
<p>2.5.1 nicht versuchen, die Software oder Teile davon in gleich welcher Form oder mit Hilfe irgendeines Mediums oder mit gleich welchen Mitteln zu kopieren, zu modifizieren, zu duplizieren, abgeleitete Werke davon anzufertigen, diese einzurahmen (framing), zu spiegeln, neu zu veröffentlichen, downzuloaden, zu zeigen, zu übertragen oder zu verbreiten; oder</p>
<p>2.5.2 nicht versuchen, die Software oder Teile davon zu dekompilieren, zu disassemblieren, zurückzuentwickeln (reverse engineering) oder in sonstiger Weise in ein von Menschen erkennbares Format zu bringen; oder</p>
<p>2.5.3 nicht auf die Services oder Teile davon zugreifen, um ein Produkt zu bauen oder eine Leistung anzubieten, welche mit den Services konkurrieren; oder</p>
<p>2.5.4 die Services nicht dazu verwenden, um Leistungen für Dritte zu erbringen; oder</p>
<p>2.5.5 die Services nicht in Unterlizenz vergeben, verkaufen, vermieten, verleasen, übertragen, abtreten, verbreiten, zeigen, offenlegen oder in sonstiger Weise gewerblich nutzen oder sie Dritten außer den autorisierten Nutzern zur Verfügung stellen; oder</p>
<p>2.5.6 nicht versuchen, Zugriff auf die Services in anderer als unter dieser Ziffer 2 genannten Art und Weise zu erlangen oder Dritten dabei behilflich zu sein.</p>
<p>2.6 Der Kunde bemüht sich nach besten Kräften, den unbefugten Zugriff auf die Services bzw. deren Nutzung zu verhindern und wird im Falle des unbefugten Zugriffs bzw. Nutzung rocketbatch hierüber unverzüglich in Kenntnis setzen.</p>
<br />
<p><b>3. Zusätzliche Nutzerabonnements</b></p>
<br />
<p>Der Kunde kann jederzeit während der Laufzeit des Abonnements zusätzliche Nutzerabonnements über die im Auftragsformular genannte Anzahl hinaus und zu der im Antragsformular genannten zusätzlichen Gebühr erwerben. rocketbatch gewährt dem Kunden Zugriff auf die Services für diese zusätzlich autorisierten Nutzer nach Maßgabe der Bestimmungen dieses Vertrages.</p>
<br />
<p><b>4. Verfügbarkeit der Services und Support</b></p>
<br />
<p>4.1 rocketbatch unternimmt wirtschaftlich vertretbare Anstrengungen, die Services (außer Fremdanwendungen) 24 Stunden am Tag, sieben Tage in der Woche und über mindestens 99,9 % der Zeit zur Verfügung zu stellen, außer:-</p>
<p>4.1.1 während geplanter Stillstandzeiten außerhalb der Geschäftszeiten, vorausgesetzt, der Kunde wird hierüber 24 Stunden im Voraus unterrichtet;</p>
<p>4.1.2 bei Überlastung dergestalt, dass eine hohe Benutzerfrequenz zur Betriebsunterbrechung führt, die vom Kunden durch Überschreiten der vereinbarten Kapazität verursacht wurde;</p>
<p>4.1.3 bei Unerreichbarkeit des Kunden: wenn der Kunde aus irgendeinem Grund nicht erreicht werden kann, um ein Verfügbarkeitsproblem zu lösen, dann läuft die Zeit solange nicht weiter bis rocketbatch in der Lage ist, mit dem Kunden Kontakt herzustellen, damit mit der Abhilfe des Verfügbarkeitsproblems begonnen werden kann;</p>
<p>4.1.4 bei Sachverhalten, die sich aus Problemen ergeben haben, denen die Nichteinhaltung vereinbarter Verfahren durch den Kunden zugrunde liegt oder die durch nicht genehmigte Änderungen der Services durch den Kunden verursacht wurden;</p>
<p>4.1.5 im Falle einer wesentlichen Verletzung von Vertragsbedingungen durch den Kunden; oder</p>
<p>4.1.6 bei Vorliegen höherer Gewalt (s. Ziffer 15.8).</p>
<p>4.2 rocketbatch erbringt als Bestandteil der Services kostenfrei für den Kunden ihre Standard-Supportleistungen während der Geschäftszeiten gemäß ihrer Richtlinie über Supportleistungen für Standard-Supportleistungen, die zum Zeitpunkt der Erbringung dieser Supportleistungen gültig sind. rocketbatch kann die Richtlinie SLA Supportleistungen jederzeit für Verbesserungen nach ihrem alleinigen Ermessen ändern.</p>
<p>4.3 Der Kunde kann erweiterte Supportleistungen separat als Paket erwerben, wie dies von rocketbatch jeweils angeboten wird und sich im Einzelnen aus dem Auftragsformular ergibt, und zwar zu den zum entsprechenden Zeitpunkt geltenden Gebührensätzen von rocketbatch für solche zusätzlichen Supportleistungen (die dem Kunden von rocketbatch zu gegebener Zeit mitgeteilt werden).</p>

<br />
<p><b>5. Kosten und Zahlung</b></p>
<br />
<p>5.1 Der Kunde zahlt die Abonnementgebühr und alle weiteren geltenden Gebühren in Bezug auf die Services gemäß dem Auftragsformular an rocketbatch nach Maßgabe dieser Ziffer 5. Vorbehaltlich anders lautender Vereinbarungen aus dem Auftragsformular kann rocketbatch die Abonnementgebühr jährlich im Voraus am oder nach dem Datum des Inkrafttretens in Rechnung stellen, und danach an jedem Jahrestag des ursprünglichen Rechnungsdatums für die Dauer der Laufzeit des Abonnements.</p>
<p>5.2 Soweit im Auftragsformular nichts anderes festgehalten ist, sind alle Gebühren und genehmigten Auslagen vom Kunden vollständig und ohne Abzug innerhalb von 30 (dreißig) Tagen nach dem Rechnungsdatum zu zahlen.</p>
<p>5.3 Sollte rocketbatch keine Zahlung auf Rechnungen zum jeweiligen Fälligkeitsdatum erhalten haben, kann rocketbatch unbeschadet ihrer sonstigen Rechte und Rechtsbehelfe:</p>
<p>5.3.1 nach schriftlicher Vorankündigung an den Kunden mit einer Frist von 30 (dreißig) Arbeitstagen ohne Haftung gegenüber dem Kunden dessen Passwort, Konto und Zugriff auf die Services oder Teile davon deaktivieren, wobei rocketbatch in keiner Weise verpflichtet ist, die Services oder Teile davon in der Zeit zu erbringen, in der die entsprechende Rechnung oder Rechnungen noch ausstehen; und</p>
<p>5.3.2 Zinsen auf diese fälligen Beträge in Höhe (i) eines jährlichen Zinssatzes von 4 % oder (ii) des gesetzlichen Verzugszinssatzes gemäß geltendem Recht, je nachdem, welcher Betrag höher ist, berechnen.</p>
<p>5.4 Alle Beträge und Gebühren, auf die in diesem Vertrag Bezug genommen wird, sind nicht erstattungsfähig und sind ohne Steuern angegeben. Der Kunde ist selbst für sämtliche Steuern in Bezug auf diesen Vertrag, die Nutzung der Services bzw. den Zugriff auf diese sowie für deren Zahlung verantwortlich.</p>
<p>5.5 rocketbatch ist berechtigt, die Jahresgebühr für Nutzerabonnements ab Beginn eines jeden Verlängerungszeitraums um zehn Prozent (10%) zu erhöhen.</p>
<br />
<p><b>6. Pflichten des Kunden</b></p>
<br />
<p>6.1 Der Kunde:</p>
<p>6.1.1 erbringt alle erforderlichen Mitwirkungsleistungen und erteilt alle Auskünfte, die nach vernünftigem Ermessen von rocketbatch für die Erbringung der Services benötigt werden. Im Falle von Verzögerungen der Unterstützung durch den Kunden kann rocketbatch vereinbarte Terminpläne oder Liefertermine angemessen anpassen;</p>
<p>6.1.2 stellt sicher, dass die autorisierten Nutzer die Services gemäß den Bedingungen dieses Vertrages nutzen, alle geltenden Gesetze und Vorschriften hinsichtlich der gemäß diesem Vertrag vorgenommenen Handlungen einhalten, und die Services ausschließlich zu rechtmäßigen Zwecken nutzen. Der Kunde haftet für Vertragsverletzungen durch die autorisierten Nutzer;</p>
<p>6.1.3 ist ausschließlich für die Bereitstellung und Wartung seiner Netzwerkanbindung und Telekommunikationsverbindungen verantwortlich sowie für alle Probleme, eingetretene Zustände, Verzögerungen und Lieferausfälle, die auf der Netzwerkanbindung oder den Telekommunikationsverbindungen des Kunden beruhen oder damit im Zusammenhang stehen;</p>
<p>6.1.4 bemüht sich nach besten Kräften, den unbefugten Zugriff auf die Services bzw. deren Nutzung zu verhindern und wird im Falle des unbefugten Zugriffs bzw. Nutzung rocketbatch hierüber unverzüglich in Kenntnis setzen;</p>
<p>6.1.5 fügt keine unangemessenen Inhalte oder Viren, sonstige Informationen bzw. sonstiges Material ein, welche insgesamt oder teilweise bzw. der Zugriff darauf oder deren Nutzung eine Straftat darstellen oder in sonstiger Weise ungesetzlich sind, einschließlich der Verletzung von Rechten am geistigen Eigentum Dritter. rocketbatch behält sich das Recht vor, ist aber nicht verpflichtet, derartige Inhalte von dort, wo rocketbatch nach ihrem eigenen billigen Ermessen diese Inhalte als unangemessene Inhalte ansieht, mit entsprechender Mitteilung an den Kunden zu entfernen;</p>
<p>6.1.6 ist ausschließlich verantwortlich für das Moderieren der von den autorisierten Nutzern geposteten Inhalte und für die Belehrung der autorisierten Nutzer mit kundeneigenen Richtlinien darüber, was sie mittels der Services posten dürfen und was nicht;</p>
<p>6.1.7 ist ausschließlich für die Genauigkeit, Vollständigkeit, Gestaltung, Angemessenheit, Erstellung, Wartung und Updates aller bei der Nutzung der Services verwendeten Kundendaten verantwortlich. rocketbatch haftet nicht für Fehler oder Ungenauigkeiten der Kundendaten oder über ihre Verantwortung hinaus, die Kundendaten auf Weisung des Kunden akkurat wiederzugeben; und</p>
<p>6.1.8 ist verantwortlich für die Einholung aller erforderlichen Zulassungen und Genehmigungen, die zur Nutzung der Kundendaten (falls zutreffend hier insbesondere die Zulassungen und Genehmigungen der Eigentümer oder Genehmigungsinhaber von Informationen Dritter) und im Rahmen der Services erforderlich sind, und der Kunde gewährleistet und sichert zu, dass diese Zulassungen und Genehmigungen eingeholt wurden.</p>
<br />
<p><b>7. Gewährleistung</b></p>
<br />
<p>7.1 rocketbatch gewährleistet, dass die Services (ohne Fremdanwendungen) über die Laufzeit des Abonnements mit hinreichender Sachkenntnis und Sorgfalt erbracht werden.</p>
<p>7.2 Die in Ziff. 7.1 gegebene Gewährleistung gilt nicht, soweit die Abweichung darauf beruht, dass der Kunde die Services nicht entsprechend den Anweisungen von rocketbatch genutzt hat, oder dass die Services durch andere als rocketbatch oder deren Erfüllungs- und Verrichtungsgehilfen modifiziert oder geändert wurden. Weichen die Services von der Gewährleistung gemäß Ziff. 7.1 ab, unternimmt rocketbatch auf eigene Kosten alle wirtschaftlich vertretbaren Anstrengungen zur unverzüglichen Abhilfe der Abweichung oder stellt dem Kunden eine Alternativlösung zur Verfügung, mit der die gewünschte Leistung erbracht wird. Diese Abhilfe oder Ersatzleistung stellt den einzigen und ausschließlichen Rechtsbehelf des Kunden und die einzige und ausschließliche Haftung von rocketbatch für den Gewährleistungsbruch dar. Unbeschadet des Vorstehenden:</p>
<p>7.2.1 übernimmt rocketbatch keine Gewährleistung für eine unterbrechungs- oder störungsfreie Nutzung der Services durch den Kunden oder dafür, dass die Services und/oder die vom Kunden durch die Services erhaltenen Informationen den Anforderungen des Kunden entsprechen;</p>
<p>7.2.2 ist rocketbatch nicht verantwortlich für Verzögerungen, Lieferausfälle oder sonstige Schäden, die sich aus dem Zugriff des Kunden auf die Services und deren Nutzung über Fremdanwendungen (wie z.B. Google) oder den Datentransfer über Kommunikationsnetze und -einrichtungen, einschließlich des Internet ergeben. Der Kunde erkennt an, dass die Services mit Einschränkungen, Verzögerungen oder sonstigen Problemen verbunden sein können, die in der Natur der Nutzung derartiger Kommunikationseinrichtungen liegen;</p>
<p>7.2.3 ist rocketbatch nicht verantwortlich für Viren, die von rocketbatch trotz des Einsatzes wirtschaftlich vertretbarer aktueller Methoden zur Erkennung von Viren nicht entdeckt wurden;</p>
<p>7.2.4 haften weder rocketbatch noch ihre Lieferanten in irgendeiner Weise für die Genauigkeit, Vollständigkeit oder Rechtzeitigkeit von Kundendaten oder für auf Kundendaten gestützte Entscheidungen oder Handlungen des Kunden, eines autorisierten Nutzers oder sonstiger Dritter.</p>
<p>7.3 Mit Ausnahme der ausdrücklich in dieser Ziffer 7 enthaltenen Regelungen schließen rocketbatch (und ihre verbundenen Unternehmen und Lieferanten) jegliche sonstige ausdrückliche, konkludente bzw. gesetzlich vorgesehene Gewährleistungshaftung aus, einschließlich der Gewährleistungen und Bedingungen für handelsübliche Qualität, Genauigkeit, Übereinstimmung mit der Beschreibung, Eignung für einen bestimmten Zweck oder Gebrauch und zufriedenstellende Qualität, sowie Nichtverletzung von Rechten.</p>
<p>7.4 Installiert oder aktiviert der Kunde Fremdanwendungen, die mit den Services genutzt werden sollen, bestätigt er, dass rocketbatch den Anbietern dieser Fremdanwendungen den Zugriff auf Kundendaten und Daten autorisierter Nutzer gestatten kann, falls und soweit dies für eine Interoperabilität zwischen diesen Fremdanwendungen und den Services erforderlich ist. rocketbatch ist nicht verantwortlich für die Offenlegung, Modifizierung oder Löschung von Kundendaten und Daten autorisierter Nutzer aufgrund dieses Zugriffs durch Anbieter von Fremdanwendungen, wobei ein solcher Zugriff auf und die Nutzung von Kundendaten und Daten autorisierter Nutzer den Datenschutzerklärungen des jeweiligen Anbieters der Fremdanwendung unterliegen. Die Services geben dem Kunden die Möglichkeit, einen derartigen Zugriff dadurch einzuschränken, dass autorisierte Nutzer nicht in der Lage sind, diese Fremdanwendungen zur Nutzung mit den Services zu installieren oder zu aktivieren.</p>
<p>7.5 Services können auch in anderer Weise gemäß den Angaben im Auftragsformular eingeschränkt werden, z. B. durch die Beschränkung der Speicherkapazität, der Bandbreite und/oder der Dateigröße für Anhänge, wie z. B. Fotos oder Dateien.</p>
<br />
<p><b>8. Kundendaten.</b><br /></p>
<br />
<p>8.1 Dem Kunden stehen sämtliche Rechte und Ansprüche an allen Kundendaten zu und er ist ausschließlich für die Rechtmäßigkeit, Angemessenheit, Vollständigkeit, Zuverlässigkeit, Integrität, Genauigkeit und Beschaffenheit der Kundendaten und der Daten autorisierter Nutzer verantwortlich.</p>
<p>8.2 Der Kunde gewährt rocketbatch und mit ihr verbundenen Unternehmen ausschließlich dafür, dass rocketbatch dem Kunden die Services zur Verfügung stellen kann, eine nicht exklusive Lizenz über die Laufzeit des Abonnements und weitere 30 Tage, (i) die Kundendaten zu hosten, zu kopieren, zu übertragen und anzuzeigen und die Kundendaten in die rocketbatch-Daten zu inkorporieren, und (ii) nötigenfalls, und auch nur soweit dies für die Erbringung der Services erforderlich ist, die Kundendaten an die von rocketbatch eingesetzten Anbieter von Fremdanwendungen nach Maßgabe der Datenschutzerklärung von rocketbatch<br />
weiterzugeben.</p>
<p>8.3 Der Kunde erkennt an, dass die Verantwortung für alle Kundendaten sowie jegliche Kommunikation mit anderen oder der autorisierten Nutzer untereinander unter Einsatz der Services ausschließlich bei ihm liegt, und dass rocketbatch eine Haftung gleich welcher Art für Verletzungen von Urheberrechten oder von Rechten anderer Personen oder Gesetzesverstöße ablehnt, insbesondere für die Verletzung oder widerrechtliche Verwendung von Urheberrechten, Warenzeichen oder von sonstigen Eigentumsrechten natürlicher oder juristischer Personen aus oder in Bezug auf die Kundendaten. Der Kunde verpflichtet sich, rocketbatch gegen Verluste, Schäden, Klagen oder Klagegründe, Prozesse, Forderungen, Ansprüche, Strafen und Zinsen, die sich aus oder im Zusammenhang mit diesen Kundendaten ergeben, schadlos zu halten und von solchen freizustellen, sofern und soweit diese nicht auf Vorsatz von rocketbatch beruhen.</p>
<p>8.4 rocketbatch befolgt ihre Schutzverfahren für Kundendaten und Daten autorisierter Nutzer, die in der jeweils von rocketbatch in deren Ermessen geänderten Fassung der Notfallwiederherstellungsrichtlinie festgelegt sind, die dem Kunden auf Anfrage zur Verfügung gestellt wird. Im Falle des Verlusts oder der Beschädigung der Kundendaten besteht der einzige und ausschließliche Rechtsbehelf des Kunden darin, dass rocketbatch wirtschaftlich vertretbare Anstrengungen zur Wiederherstellung der verloren gegangenen oder beschädigten Kundendaten aus dem letzten Backup dieser Kundendaten unternimmt, die von rocketbatch gemäß dem in der Notfallwiederherstellungsrichtlinie beschriebenen Archivierungsverfahren gepflegt wurden. rocketbatch haftet nicht für die von Dritten (mit Ausnahme der von rocketbatch zur Erbringung von Leistungen im Rahmen der Kundendatenpflege und -backup beauftragten Subunternehmen) verursachten Verluste, Vernichtung, Änderung oder Offenlegung von Kundendaten, soweit diese nicht ausschließlich auf der Fahrlässigkeit oder Vorsatz von rocketbatch beruhen.</p>
<p>8.5 Der Kunde erkennt an, dass rocketbatch nicht verpflichtet ist, Kundendaten (einschließlich Informationen Dritter) zu redigieren, zu moderieren oder zu modifizieren. rocketbatch behält sich jedoch das Recht vor, beliebige Kundendaten (oder Informationen Dritter) zu entfernen, von denen rocketbatch in angemessener Erwägung glaubt, dass sie gegen Gesetze oder Vorschriften verstoßen oder die Rechte Dritter oder diesen Vertrag verletzen, und/oder als unangemessene Inhalte angesehen werden. rocketbatch setzt den Kunden über das Entfernen von Kundendaten (oder Informationen Dritter) gemäß dieser Bestimmung in Kenntnis. rocketbatch haftet in keiner Weise in Bezug auf Kundendaten, Informationen Dritter oder sonstiges Material, auf welche unter Verwendung der Services zugegriffen werden kann. rocketbatch lehnt ausdrücklich jegliche Haftung für Betrug ab, der im Zusammenhang mit den Services begangen wurde.</p>
<br />
<p><b>9. Datenschutz</b></p>
<br />
<p>9.1 rocketbatch erkennt an, dass der Kunde in Bezug auf Daten der autorisierten Nutzer (oder Kundendaten, die personenbezogene Daten beinhalten) als der für die Datenverarbeitung Verantwortliche handelt. Soweit rocketbatch im Rahmen der Erbringung der Services Zugriff auf die Daten autorisierter Nutzer (oder Kundendaten, die personenbezogene Daten beinhalten) hat und diese verarbeitet, bemüht sich rocketbatch nach Kräften:</p>
<p>9.1.1 um Datensicherheit der personenbezogenen Daten des Kunden und seiner autorisierten Nutzer und das Vorhandensein geeigneter technischer und organisatorischer Maßnahmen, mit denen ein angemessenes Sicherheitsniveau bei der Bearbeitung der personenbezogenen Daten des Kunden und seiner autorisierten Nutzer gewährleistet ist, sowie um den Schutz der personenbezogenen Daten des Kunden und seiner autorisierten Nutzer vor unbefugter oder ungesetzlicher Verarbeitung oder vor dem zufälligen Untergang, der Vernichtung oder Schäden;</p>
<p>9.1.2 um Wahrung der Integrität der personenbezogenen Daten des Kunden und seiner autorisierten Nutzer und um Verhinderung des Verlusts oder der Beschädigung der personenbezogenen Daten des Kunden und seiner autorisierten Nutzer;</p>
<p>9.1.3 darum, die personenbezogenen Daten des Kunden und seiner autorisierten Nutzer nur gemäß den Anweisungen und Anleitungen des Kunden und seiner autorisierten Nutzer zu verarbeiten; und</p>
<p>9.1.4 darum, dem Kunden angemessene Unterstützung und Informationen zukommen zu lassen, wie dies erforderlich ist, damit der Kunde seine Pflichten aus den auf ihn anwendbaren gesetzlichen Datenschutzregelungen erfüllen kann.</p>
<p>9.2 Für die Zwecke dieses Abschnitts 9 haben die Begriffe "Datenverantwortlicher", "personenbezogene Daten", und "Verarbeitung" die in der Datenschutz-Grundverordnung (EU) 2016/679 festgelegte Bedeutung.</p>
<p>9.3 rocketbatch muss jederzeit alle geltenden Datenschutzgesetze einhalten.</p>
<br />
<p><b>10. Vertraulichkeit</b></p>
<br />
<p>10.1 Beiden Parteien wird zur Erfüllung ihrer vertraglichen Pflichten unter Umständen Zugriff auf vertrauliche Informationen der anderen Partei gegeben. Vertrauliche Informationen umfassen nicht solche Informationen, die:</p>
<p>(a) öffentlich bekannt sind oder werden, und dies nicht aufgrund einer Handlung oder Unterlassung der Empfängerpartei geschehen ist;</p>
<p>(b) sich bereits vor der Offenlegung im rechtmäßigen Besitz der anderen Partei befunden haben;</p>
<p>(c) der Empfängerpartei von einem Dritten ohne das Vorliegen von Beschränkungen hinsichtlich der Offenlegung rechtmäßig mitgeteilt wurden;</p>
<p>(d) von der Empfängerpartei selbständig entwickelt wurden und diese selbständige Entwicklung schriftlich nachgewiesen werden kann; oder</p>
<p>(e) kraft Gesetzes, aufgrund der Anordnung eines zuständigen Gerichts oder einer Aufsichts- oder Verwaltungsbehörde offengelegt werden müssen.</p>
<p>10.2 Die Parteien behandeln die vertraulichen Informationen der jeweils anderen Partei vertraulich und stellen die vertraulichen Informationen außer auf der Grundlage gesetzlicher Anforderungen keinem Dritten zur Verfügung. Sie verwenden die vertraulichen Informationen auch nicht für andere Zwecke als zur Durchführung dieses Vertrages. Die Parteien stellen mithilfe angemessener Maßnahmen sicher, dass die für sie zugänglichen vertraulichen Informationen der anderen Partei nicht von ihren Mitarbeitern oder Vertretern unter Verletzung der Vertragsbestimmungen offengelegt oder verbreitet werden.&nbsp;</p>
<p>Die Parteien sind nicht für von Dritten verursachten Verlust, Vernichtung, Änderung oder Offenlegung vertraulicher Informationen verantwortlich.</p>
<p>10.3 Der Kunde erkennt an, dass die Einzelheiten der Software, Services, rocketbatch-Daten und die Ergebnisse der bezüglich der Services durchgeführten Leistungstests vertrauliche Informationen von rocketbatch darstellen. rocketbatch erkennt an, dass die Kundendaten vertrauliche Informationen des Kunden darstellen.</p>
<p>10.4 rocketbatch ist berechtigt, den Kunden als Referenz zu Werbezwecken namentlich zu nennen, es sei denn, der Kunde hat rocketbatch ausdrücklich etwas anderes schriftlich mitgeteilt und das diesbezügliche Anliegen des Kunden ist angemessen.</p>
<br />
<p><b>11. Rechte am geistigen Eigentum von rocketbatch</b><br /></p>
<br />
<p>Der Kunde erkennt an und erklärt sich damit einverstanden, dass rocketbatch und/oder deren Lizenzgeber sämtliche Rechte am geistigen Eigentum an der Software, den Services und den rocketbatch-Daten innehaben. Mit Ausnahme ausdrücklich anders lautender Regelungen aus diesem Vertrag verleiht dieser Vertrag dem Kunden keinerlei Ansprüche auf Patente, Urheberrechte, Datenbasisrechte, Geschäftsgeheimnisse, Handelsnamen, (eingetragene oder nicht eingetragene) Warenzeichen oder sonstige Rechte oder Lizenzen in Bezug auf die Software, die Wartungs-Releases, die Services oder die rocketbatch-Daten.</p>
<br />
<p><b>12. Schadloshaltung</b></p>
<br />
<p>12.1 rocketbatch verteidigt den Kunden gegen Ansprüche, Forderungen, Klagen oder Prozesse Dritter gegen den Kunden, in denen behauptet wird, dass die vertragsgemäße Nutzung eines gekauften Service die Rechte am geistigen Eigentum dieses Dritten verletzt oder widerrechtlich verwendet ("<b>Anspruch</b>") und hält den Kunden schadlos gegen Schadensersatz, Anwaltshonorare und Kosten, zu denen der Kunde rechtskräftig verurteilt wird aufgrund oder in Höhe von Beträgen, die der Kunde im Rahmen eines vom Gericht genehmigten Vergleichs zur Beilegung des Anspruchs bezahlt hat, unter der Voraussetzung, dass der Kunde (a) rocketbatch schriftlich über jeden Anspruch in Kenntnis setzt, (b) rocketbatch die ausschließliche Kontrolle über die Verteidigung und die vergleichsweise Erledigung des Anspruchs überlässt (wobei jedoch rocketbatch einen Anspruch mittels Vergleich nur dann beilegen kann, wenn er den Kunden bedingungslos aus der Haftung entlässt) und (c) rocketbatch auf deren Kosten angemessen unterstützt. Wenn rocketbatch über einen Anspruch in Kenntnis gesetzt wird, kann rocketbatch nach ihrem Ermessen und kostenlos für den Kunden (i) den Service so modifizieren, dass er keine Rechtsverletzung oder widerrechtliche Verwendung mehr darstellt ohne die Gewährleistung aus Ziff. 7.1 (Gewährleistungen) zu verletzen, (ii) eine Lizenz für die weitere vertragsgemäße Nutzung dieses Service durch den Kunden einholen, oder (iii) das Abonnement des Kunden für diesen Service kündigen und dem Kunden eventuell im Voraus entrichtete Gebühren für die verbleibende Laufzeit des Abonnements erstatten. Die vorstehenden Verteidigungs- und Schadloshaltungspflichten gelten nicht, wenn ein Anspruch auf Folgendem beruht:</p>
<p>(a) Fremdanwendungen oder deren Nutzung durch den Kunden;</p>
<p>(b) Modifizierung der Services durch andere als rocketbatch oder ihre Auftragnehmer;</p>
<p>(c) Nutzung der Services durch den Kunden in einer Art und Weise, die nicht dem Vertrag und/oder dem Auftragsformular entspricht;</p>
<p>(d) Nutzung der Services durch den Kunden in Verbindung mit Fremdanwendungen oder sonstigen Fremdleistungen oder -produkten, wobei diese Leistungen oder Produkte den Anspruch verursacht haben; oder</p>
<p>(e) Nutzung der Services durch den Kunden nach Mitteilung von rocketbatch oder der zuständigen Behörde über den behaupteten oder effektiven Anspruch.</p>
<p>12.2 Der Kunde verteidigt rocketbatch gegen Ansprüche, Forderungen, Klagen oder Prozesse Dritter gegen rocketbatch, in denen behauptet wird, dass die Kundendaten oder die Nutzung eines Service durch den Kunden die Rechte am geistigen Eigentum dieses Dritten verletzt oder widerrechtlich verwendet oder gegen geltendes Recht verstößt ("Anspruch gegen rocketbatch") und hält rocketbatch schadlos gegen Schadensersatz, Anwaltshonorare und Kosten, zu denen rocketbatch rechtskräftig verurteilt wird aufgrund oder in Höhe von Beträgen, die rocketbatch im Rahmen eines vom Gericht genehmigten Vergleichs zur Beilegung des Anspruchs gegen rocketbatch bezahlt hat, unter der Voraussetzung, dass rocketbatch (a) den Kunden unverzüglich schriftlich über jeden Anspruch gegen rocketbatch in Kenntnis setzt, (b) dem Kunden die ausschließliche Kontrolle über die Verteidigung und die vergleichsweise Erledigung des Anspruchs gegen rocketbatch überlässt (wobei jedoch der Kunde einen Anspruch gegen rocketbatch mittels Vergleich nur dann beilegen kann, wenn er rocketbatch bedingungslos aus der Haftung entlässt) und (c) den Kunden auf dessen Kosten angemessen unterstützt.</p>
<br />
<p><b>13. Haftungsbeschränkung</b></p>
<br />
<p>13.1 Die in dieser Ziffer genannten Ausschlüsse gelten im größtmöglichen gesetzlich zulässigen Umfang. Allerdings schließen die Parteien ihre Haftung wegen Tod oder Körperverletzung, die durch ihre Fahrlässigkeit oder die ihrer leitenden Angestellten, Mitarbeiter, Erfüllungs- oder Verrichtungsgehilfen verursacht wurden, auf Betrug oder arglistiger Täuschung beruhen, oder sonstige Haftung, die gemäß geltendem Recht nicht ausgeschlossen werden kann, nicht aus.</p>
<p>13.2 Mit Ausnahme von Beträgen, die der Kunde rocketbatch gemäß diesem Vertrag schuldet, sowie vorbehaltlich der Regelungen aus Ziff. 12 ist die Gesamthaftung der Parteien für die der jeweils anderen Partei entstandenen Verluste oder Schäden (ob aufgrund von Vertragsverletzungen, unerlaubter Handlung (einschließlich Fahrlässigkeit) oder anderer Ursachen) aus oder im Zusammenhang mit diesem Vertrag für jedes Anspruchsjahr auf den Gesamtbetrag der vom Kunden in diesem Anspruchsjahr gezahlten Gebühr beschränkt.</p>
<p>13.3 Soweit dies nach geltendem Recht zulässig ist, haften die Parteien auf keinen Fall gegenüber der jeweils anderen Partei für sich aus diesem Vertrag ergebende indirekte Schäden, Folgeschäden, oder sonstige mittelbare Schäden, oder für entgangenen Gewinn, Verlust erwarteter Einsparungen, von Geschäftschancen, des Firmenwerts, oder für Ertragsausfall, Nutzungs- oder Datenausfall (einschließlich der Beschädigung von Daten), oder für Kosten der Beschaffung von Ersatzwaren oder -dienstleistungen, unabhängig von der Ursache und der zugrunde liegenden Haftungstheorie (einschließlich aus Vertrag, unerlaubter Handlung, Fahrlässigkeit oder sonstigem), auch wenn die andere Partei auf die Möglichkeit derartiger Schäden hingewiesen wurde.</p>
<p>13.4 Zusätzlich zu den anderen in dieser Ziffer 13 genannten Ausschlüssen, haftet rocketbatch nicht, wenn die Nichterbringung der Services auf Folgendem beruht:</p>
<p>13.4.1 Netzwerk-, Hardware- oder Softwarefehler in der Ausrüstung, für den rocketbatch nicht verantwortlich ist;</p>
<p>13.4.2 Handlungen oder Unterlassungen des Kunden;</p>
<p>13.4.3 Nutzung der Services entgegen diesem Vertrag; oder</p>
<p>13.4.4 unbefugter Zugriff auf die Services einschließlich einer böswilligen Verletzung der Sicherheit.</p>
<p>13.5 Im Falle von Verlusten oder Schäden von Kundendaten sind die einzigen und ausschließlichen Rechtsbehelfe des Kunden diejenigen aus Ziff. 8.4.</p>
<br />
<p><b>14.&nbsp; Laufzeit und Kündigung</b><br /></p>
<br />
<p>14.1 Dieser Vertrag läuft ab dem Tag des Inkrafttretens über die Erstlaufzeit des Abonnements, sofern er nicht anderweitig gemäß Ziff. 12.1 oder dieser Ziffer 14 gekündigt wird. Soweit das Auftragsformular nichts anderes besagt, wird dieser Vertrag danach automatisch für aufeinander folgende Laufzeiten von jeweils 12 Monaten (jeweils als "<b>Verlängerungszeitraum</b>" bezeichnet) verlängert, sofern er nicht von einer Partei unter Einhaltung einer Frist von 60 (sechzig) Tagen schriftlich zum Ende der Erstlaufzeit des Abonnements oder der entsprechenden Verlängerungslaufzeit oder nach Maßgabe der Vertragsbestimmungen in sonstiger Weise gekündigt wird. Die Erstlaufzeit des Abonnements und alle sich daran anschließenden Verlängerungszeiträume stellen die "<b>Laufzeit des Abonnements</b>" dar. Ohne Verlängerungszeitraum enden der Zugriff auf die Services und deren Nutzung durch den Kunden automatisch.</p>
<p>14.2 Unbeschadet sonstiger Rechte oder Rechtsbehelfe, die den Parteien ggf. zur Verfügung stehen, können die Parteien diesen Vertrag unverbindlich gegenüber der anderen Partei jederzeit fristlos schriftlich kündigen, wenn die andere Partei:</p>
<p>14.2.1 wesentlich gegen ihre vertraglichen Pflichten verstoßen hat und im Falle einer Vertragsverletzung, der abgeholfen werden kann, dieser Verletzung nicht innerhalb von dreißig Tagen nach entsprechender Rüge abgeholfen hat; und</p>
<p>14.2.2 einen freiwilligen Antrag gemäß dem Konkurs- oder Insolvenzrecht stellt; ein Insolvenz- bzw. Zwangsverwalter über die Partei oder deren Vermögen bestellt wird; oder wenn eine Vermögensverwaltungsanordnung über die andere Partei ergeht oder wenn diese einen Schuldenvergleich mit ihren Gläubigern eingeht oder ihre Geschäftstätigkeit einstellt oder dies androht; oder wenn diese Partei einem entsprechenden Vorgang oder Verfahren in der jeweiligen Rechtsordnung unterliegt.</p>
<p>14.3 Bei Kündigung dieses Vertrages aus gleich welchem Grund:</p>
<p>14.3.1 enden alle mit diesem Vertrag gewährten Rechte unverzüglich;</p>
<p>14.3.2 stellt der Kunde die Nutzung der Services ein;</p>
<p>14.3.3 leistet der Kunde umgehend alle gemäß diesem Vertrag fälligen Zahlungen;</p>
<p>14.3.4 gibt jede Partei sämtliche der anderen Partei gehörende Ausrüstung, Eigentum, Software und Services sowie andere Dinge (einschließlich aller Kopien derselben) zurück und verwendet diese nicht mehr;</p>
<p>14.3.5 kann rocketbatch sämtliche Kundendaten in ihrem Besitz vernichten oder in sonstiger Weise entsorgen, sofern rocketbatch nicht spätestens 30 (dreißig) Tage nach dem Datum des Wirksamwerdens der Vertragskündigung eine schriftliche Aufforderung zur Übergabe des zuletzt durchgeführten Backup der Kundendaten zugeht. rocketbatch übergibt dem Kunden das Backup nach Erhalt dieser schriftlichen Aufforderung. Nach Ablauf dieser 30-Tage-Frist ist rocketbatch nicht verpflichtet, die Kundendaten zu pflegen oder herauszugeben und kann sie danach löschen und alle Kopien von Kundendaten unter ihrer Kontrolle vernichten, es sei denn, dies ist gesetzlich verboten.</p>
<br />
<p><b>15. Allgemeines</b></p>
<br />
<p>15.1 <b>Umfassender Charakter</b>. Dieser Vertrag stellt zusammen mit dem Auftragsformular die vollständige Vereinbarung zwischen den Parteien dar und ersetzt alle vorherigen Vereinbarungen zwischen den Parteien über den Vertragsgegenstand. Sofern nichts anderes ausdrücklich schriftlich vereinbart ist, gilt dieser Vertrag anstelle der - und hat Vorrang vor - sämtlichen Bedingungen bzw. Konditionen, die in der Korrespondenz oder anderswo enthalten sind oder auf die dort verwiesen wird, oder deren Geltung aufgrund des Handelsbrauchs oder regelmäßiger Verhaltensweise vorausgesetzt wird. Allgemeine Geschäftsbedingungen oder andere Bedingungen aus einem Auftrag oder sonstigen vom Kunden im Zusammenhang mit diesem Vertrag ausgestellten Unterlagen sind für rocketbatch nicht verbindlich. Mit dem Abschluss dieses Vertrages erkennt jede Partei an und erklärt, dass sie sich nicht auf Zusicherungen der anderen Partei gestützt hat, die nicht in diesem Vertrag enthalten sind. Derartige Zusicherungen sind ausgeschlossen. Die vorliegende Bestimmung schränkt die Haftung für betrügerisch abgegebene Zusicherungen in keiner Weise ein.</p>
<p>15.2 <b>Verzicht</b>. Der Verzicht auf ein Recht aus diesem Vertrag ist nur in schriftlicher Form wirksam und gilt nur für die Partei, gegenüber welcher der Verzicht erklärt wurde und nur für die diesem Verzicht zugrunde liegenden Umstände. Sofern nichts anderes ausdrücklich vereinbart ist, gelten die sich aus diesem Vertrag ergebenden Rechte kumulativ und schließen gesetzliche Rechte nicht aus.</p>
<p>15.3 <b>Salvatorische Klausel</b>. Sollte eine Vertragsbestimmung (oder Teile davon) von einem zuständigen Gericht oder einer zuständigen Behörde für ungültig, nicht durchsetzbar oder ungesetzlich befunden werden, bleiben die übrigen Bestimmungen in Kraft. Würde eine ungültige, nicht durchsetzbare oder ungesetzliche Bestimmung durch Teilstreichung der Bestimmung gültig, durchsetzbar oder gesetzmäßig, dann gilt diese Bestimmung mit der Änderung, die erforderlich ist, um dem wirtschaftlich Gewollten der Parteien Wirkung zu verleihen.</p>
<p>15.4<b> Geltendes Recht und Gerichtsstand</b>. Sofern im Auftragsformular nicht anders vereinbart:</p>
<p>15.4.1&nbsp;Für alle in Nordamerika ansässigen Kunden (USA, Kanada, Mexiko) schließen die Parteien hiermit die Anwendung des Übereinkommens der Vereinten Nationen von 1980 über Verträge über den internationalen Warenkauf auf diese Vereinbarung aus. Vielmehr unterliegen diese Vereinbarung und alle Streitigkeiten, die sich aus dieser Vereinbarung ergeben oder damit in Zusammenhang stehen, den Gesetzen des Staates Delaware und werden gemäß diesen ausgelegt, ohne dass auf Kollisionsgrundsätze Bezug genommen wird. Alle derartigen Streitigkeiten unterliegen der ausschließlichen Zuständigkeit der staatlichen und bundesstaatlichen Gerichte an dem im US-Bundesstaat Delaware vereinbarten Ort. Die Parteien stimmen zu und unterwerfen sich der persönlichen und ausschließlichen Zuständigkeit und dem Gerichtsstand dieser Gerichte. Im Falle von Rechtsstreitigkeiten zwischen den Parteien hat die vorherrschende Partei Anspruch auf angemessene Anwaltskosten und alle Kosten, die bei der Durchsetzung dieser Vereinbarung anfallen.</p>
<p>15.4.2&nbsp;Für alle in Deutschland ansässigen&nbsp;Vertragsparteien gilt für&nbsp;alle Streitigkeiten, die sich aus diesem Vertrag ergeben oder damit in Zusammenhang stehen, deutsches Recht ohne Hinweis auf Kollisionsnormen. Für alle Streitigkeiten sind ausschließlich die vereinbarten Berliner Landes- und Bundesgerichte zuständig, und die Parteien stimmen zu und unterwerfen sich der persönlichen und ausschließlichen Zuständigkeit und dem Gerichtsstand dieser Gerichte. Im Falle von Rechtsstreitigkeiten zwischen den Parteien hat die vorherrschende Partei Anspruch auf angemessene Anwaltskosten und alle Kosten, die bei der Durchsetzung dieser Vereinbarung anfallen.</p>
<p>15.4.3&nbsp;Für alle nicht in Deutschland ansässigen Kunden gelten für alle Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ausschließlich schweizerisches materielles Recht unter Ausschluss kollisionsrechtlicher Normen und internationaler Übereinkommen, insbesondere des UN-Kaufrechts (Wiener Übereinkommen). Das Handelsgericht des Kantons Zürich ist ausschließlich zuständig für alle Streitigkeiten, die sich aus oder im Zusammenhang mit diesem Vertrag, einschließlich seines Zustandekommens, ergeben.</p>
<p>15.5 <b>Rechte Dritter</b>. Personen, bei denen es sich nicht um Parteien dieses Vertrages handelt, sind weder zur Durchsetzung der Vertragsbestimmungen befugt, noch kommen ihnen diese zugute.</p>
<p>15.6 <b>Unterbeauftragung und Abtretung</b>. Weder rocketbatch noch der Kunde dürfen diesen Vertrag und die sich daraus ergebenden Rechte und Pflichten an Dritte abtreten oder in sonstiger Weise übertragen, oder derartige Handlungen gemäß diesem Vertrag beabsichtigen, ohne hierzu im Besitz der vorherigen schriftlichen Genehmigung der anderen Partei zu sein, die nicht unangemessen verweigert werden darf. Unbeschadet gegenteiliger Bestimmungen, beide Parteien haben das Recht, diese Vertrag nach schriftlicher Mitteilung an die andere Partei, an eines ihrer verbundenen Unternehmen oder an ein Unternehmen zu übertragen, das aus einer Fusion, Übernahme, Umstrukturierung oder sonstigen Geschäftssanierung der betroffenen Partei resultiert. Darüber hinaus darf rocketbatch ihre Pflichten aus diesem Vertrag an einen Dritten in Unterauftrag vergeben unter der Voraussetzung, dass rocketbatch weiterhin verantwortlich für die Erbringung der Services aus diesem Vertrag bleibt. Abtretungs-, Unterbeauftragungs- oder sonstige Übertragungsversuche in Verletzung dieser Bestimmung sind null und nichtig.</p>
<p>15.7 <b>Kein Gesellschaftsverhältnis oder Vertretung</b>. Dieser Vertrag bewirkt oder begründet weder eine einfache Gesellschaft zwischen den Parteien noch gestattet er einer Partei, als Beauftragte der anderen Partei zu handeln. Weiterhin sind die Parteien nicht befugt, namens oder im Auftrag der anderen Partei zu handeln oder die andere Partei in anderer Weise zu verpflichten (insbesondere durch Abgabe von Zusicherungen oder Gewährleistungen, der Übernahme einer Verpflichtung oder Haftung und der Ausübung sonstiger Rechte oder Befugnisse).</p>
<p>15.8 <b>Höhere Gewalt.</b> rocketbatch trifft keine Haftung gegenüber dem Kunden aus diesem Vertrag, wenn sie an der Erfüllung ihrer vertraglichen Pflichten gehindert wird oder diese verspätet erfüllt, oder wenn sie an der Geschäftsausübung gehindert wird aufgrund von Handlungen, Ereignissen, Unterlassungen oder Unfällen, die außerhalb der angemessenen Kontrolle von rocketbatch liegen, insbesondere Streiks, Aussperrungen oder andere arbeitsrechtliche Streitigkeiten (gleich ob dies die Belegschaft von rocketbatch oder von Dritten betrifft), Ausfall von Versorgungsleistungen oder von Verkehrs- oder Telekommunikationsnetzwerken, Naturkatastrophen, Krieg, Aufruhr, innere Unruhen, mutwillige Sachbeschädigung, Feuer, Überschwemmung oder Sturm. rocketbatch setzt den Kunden über derartige Ereignisse und deren voraussichtliche Dauer in Kenntnis.</p>
<p>15.9 <b>Mitteilungen</b>. Die gemäß diesem Vertrag abzugebenden Mitteilungen sind schriftlich auf Deutsch an die Anschrift zu richten, die sich die Parteien gegenseitig schriftlich mitteilen. Mitteilungen in Bezug auf Nichterfüllung, Kündigung oder Verlängerung sind persönlich zu übergeben oder mit einem anerkannten Übernacht-Kurierdienst oder per Einschreiben/Rückschein zu versenden. Mitteilungen von rocketbatch über Preisänderungen, Änderungen der Rabattkategorie, Produkteinstellungen, Produktänderungen, und Änderungen des Logistikzentrums können darüber hinaus auch per E-Mail an die vom Kunden jeweils genannten Personen versandt werden. Alle anderen, nicht an anderer Stelle dieser Ziff. 15.9 genannten Mitteilungen können (i) per Post oder (ii) E-Mail (info@rocketbatch.com) verschickt werden. Alle Mitteilungen gelten als abgegeben und empfangen entweder bei ihrem tatsächlichen Eingang oder 3 (drei) Tage ab dem Datum des Poststempels oder Datum und Uhrzeit des E-Mailversands, je nachdem welcher Zeitpunkt früher eintritt.</p>
<p>15.10 <b>Änderungen</b>. Mit Ausnahme ausdrücklicher anders lautender Bestimmungen dieses Vertrages kann dieser Vertrag nur schriftlich mit Unterschrift der Bevollmächtigten beider Parteien modifiziert oder geändert werden.</p>
<p>15.11 <b>Fortbestand</b>. Zusätzlich zu den Bestimmungen, die naturgemäß über eine Kündigung oder Beendigung dieses Vertrages hinaus fortbestehen, bleiben auch die Bestimmungen aus Ziffer 9, 10, 11, 12, 13, 14.3 und 15 dieses Vertrages über die Kündigung oder den Ablauf des Vertrages hinaus gültig.</p>
<p>15.12 <b>Exportkontrolle</b>. Der Service, die Software und sonstiges rocketbatch-Material unterliegen den Exportkontrollgesetzen verschiedener Länder, insbesondere derjenigen der USA, der Schweiz und Großbritanniens. Der Kunde verpflichtet sich, den Service und sonstiges rocketbatch-Material keiner staatlichen Behörde zwecks Zulassung oder sonstiger behördlicher Genehmigung ohne die vorherige schriftliche Zustimmung von rocketbatch zu unterbreiten. Weiterhin wird der Kunde den Service, die Software, rocketbatch-Daten und rocketbatch-Material nicht in Länder oder an natürliche oder juristische Personen exportieren, in bzw. an welche der Export aufgrund der vorstehenden Gesetze verboten ist. Der Kunde ist weiterhin verantwortlich für die Einhaltung aller anwendbaren behördlichen Vorschriften des Landes, in dem der Kunde registriert ist, sowie ausländischer Vorschriften bezüglich der Nutzung des Services oder sonstigen rocketbatch-Materials durch den Kunden und seine autorisierten Nutzer. Der Kunde nimmt keine Handlungen vor, die bewirken, dass rocketbatch gegen diese Exportkontrollgesetze und -vorschriften verstößt.</p>

</div>
</div>


        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 100px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 150px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default About;